<!--PLM产品生命周期管理系统-->
<template>
  <div class="plm-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="PLM产品生命周期管理系统" @btnClick="bannerClick" :price="bannerPrice"
    imgStyle="width:413px;height:381px" :imgSrc="`${require('@/assets/img/solution/PLM产品生命周期管理系统.png')}`" contentStyle="width:90%"/>
    <!--PLM系统能解决的核心痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">PLM系统能解决的核心痛点</p>
        <div class="module-col">
          <el-row :gutter="10">
            <template v-for="(list,index) of dataList">
              <el-col :span="8">
                <div :class="'tabs-col tabs-col-'+index" ref="plm">
                  <ul>
                    <template v-for="ul of list.list">
                      <li class="list-style-type">{{ ul }}</li>
                    </template>
                  </ul>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--PLM系统的主要功能-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">PLM系统的主要功能</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="li of dataList2">
              <el-col :span="12">
                <div class="module-li">
                  <div class="li-title">
                    <div class="col-img"
                         :style="'background: url('+require('@/assets/img/solution/'+li.icon)+') no-repeat'"/>
                    <p class="li--title">{{ li.name }}</p>
                  </div>
                  <p class="li-introduce">{{ li.introduce }}</p>
                  <ul>
                    <template v-for="ul of li.list">
                      <li class="list-style-type">{{ ul }}</li>
                    </template>
                  </ul>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--合同管理产品亮点-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="color: #FFFFFF">PLM的主要应用场景</p>
        <div class="module-tabs">
          <template v-for="list of datalist3">
            <div :class="activeName==list.id ? 'active tabs-list' : 'tabs-list'" @click="activeName=list.id">
              {{ list.name }}
            </div>
          </template>
        </div>
        <template v-for="data of datalist3">
          <template v-if="data.id==activeName">
            <div class="module--content">
              <div class="content-left">
                <p class="title">{{ data.title }}</p>
                <div class="content" v-html="data.page1"/>
              </div>
              <div class="content-right">
                <div v-html="data.page2"></div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!--PLM系统能解决的核心痛点-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云PLM的优势</p>
        <div class="module-col">
          <el-row :gutter="15">
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-1"/>
                  <p class="title">操作简单</p>
                  <p class="content">PLM奉行极简设计原则，让用户在使用产品的过程中可以轻松上手，操作起来非常简单</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-2"/>
                  <p class="title">易于集成</p>
                  <p class="content">不管是与办公软件、结构设计、电子设计三大类工具的集成，还是与其他系统的互联互通，都能轻松实现</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-3"/>
                  <p class="title">自主可控</p>
                  <p class="content">通过标准的API接口和Webservice接口，实现PLM的数据同企业的其他业务系统和移动办公系统的深度集成</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-4"/>
                  <p class="title">快速迭代</p>
                  <p class="content">基于用户的业务需求对产品作出快速反馈，推动产品不断迭代升级，并提供最佳实践</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'PLM产品生命周期管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
export default {
  name: "plm",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerPrice:'市场价格：460000元',
      bannerContent: '如何有效地缩短产品研发周期、改善产品服务的设计迭代流程、有效降低产品的研发成本，也是企业面临的主要问题之一。PLM产品生命周期管理系统在优化产品研发的全管理流程、增强企业产品的市场竞争力方面具有关键的作用。',
      activeName: '1',
      dataList: [
        {
          list: [
            '文件发布与共享体制混乱',
            '技术工程缺少协同工作平台',
            '不同格式的数据无法共享',
            '设计软件不能为后期BOM提供数据'
          ]
        },
        {
          list: [
            '难以按照标准对产品研发生命周期进行有效控制',
            '难以实时监控项目实际进度',
            '难以控制项目的过程',
            'ISO执行与工作效率矛盾问题',
            '项目的绩效考核出现问题',
          ]
        },
        {
          list: [
            '文档数据没有管理规范',
            '难以实现产品信息标准化',
            '没有建立标准的研发过程及数据审核过程'
          ]
        },
        {
          list: [
            '文档内容及格式混乱',
            '物料的编码混乱，经常编码和物料不对版',
            '产品的采标率太低'
          ]
        },
        {
          list: [
            '难以保证数据的合法性、准确性',
            '难以体现数据之间的关联性',
            '难以体现数据之间的借用关系',
            '难以BOM的准确性',
            '难以对文档进行缜密的权限控制',
            '难以防范病毒及系统问题起的数'
          ]
        },
        {
          list: [
            '任务指令容易出错',
            '任务执行难以实时监督',
            '难以控制任务的成果',
            '人员的绩效考核难以执行',
            '技术人员之间沟通不畅'
          ]
        },
      ],
      dataList2: [
        {
          name: '数据管理',
          introduce: '以数据为核心，将数据管理贯穿产品的全生命周期',
          icon: '数据管理.png',
          list: [
            '物料管理：物料编码规则、物料属性定义、物料的生命周期管理、物料分类定义',
            'BOM管理：BOM结构、替代料管理、BOM正反结构查询',
            '制造商管理：制造商管理、制造商部件号管理',
            '文档管理：文档分类管理、文档版本管理、文档的权限管理'
          ]
        },
        {
          name: '变更管理',
          introduce: '根据用户的个性化需求，支持用户定义各种规则，对变更进行跟踪和管理',
          icon: '变更管理.png',
          list: [
            '内置了行业标准的ECR-ECO的工程变更解决方案',
            '可以根据客户的个性化需求进行业务流程配置',
            '提供了行业领先的用户可编程引擎，支持用户定义各种规则'
          ]
        },
        {
          name: '项目管理',
          introduce: '项目计划+项目进度+项目交付+项目报表',
          icon: '项目管理.png',
          list: [
            '采用最新的BS技术实现项目中的各种管理功能',
            '包含项目计划、交付、资源、工时、费用、报表等核心功能',
            '帮助企业实现项目集的管理，规范项目数据、缩短研发周期'
          ]
        },
        {
          name: '在线可视化',
          introduce: '目前支持超过40多种主流的文件格式在线预览，所见即所得',
          icon: '在线可视化.png',
          list: [
            '采用最新的BS架构实现了文件的预览，不需要用户客户端安装任何的客户端软件',
            '支持主流2D文件(Office、CAD、压缩文件、文本文件、图片、音频)格式的预览',
            '支持主流3D文件(prt、asm、pcb、stp) 格式的预览'
          ]
        },
        {
          name: '数据建模',
          introduce: '通过数据建模，支持客户的个性化需求',
          icon: '数据建模.png',
          list: [
            '提供强大的配置平台，支持根据客户的个性化需求配置基础设置、对象分类、对象属性、工作流程、权限实现完全可配置的业务，支持数据、流程、字段级别的数据建模和精细化的权限控制'
          ]
        },
        {
          name: '第三方接入',
          introduce: '轻松实现与第三方软件的对接',
          icon: '第三方接入.png',
          list: [
            '通过标准的API接口和Webservice接口，实现PLM的数据同企业的其他业务系统和移动办公系统的深度集成',
            '自带主流的ERP集成接口和移动端集成，支持数据导出'
          ]
        },
      ],
      datalist3: [
        {
          id: '1',
          name: '化工行业',
          title: '化工行业应用场景',
          page1: '目前，世界范围内化工产业发展迅速，新产品不断推出。同时，市场竞争加剧，产品同质化倾向比较明显，对数字化转型的要求也越来越高。需要覆盖产品全生命周期的闭环研发体系、跨部门跨地域的协同研发体系、基于数据收集和分析的数据建模数据管理体系、以及与第三方系统进行高效对接的系统集成机制。<br/><br/>' +
              'PLM基于10多年的PLM咨询实施经验，因应化工行业的发展需求，为化工行业提供最佳实践。系统包含数据管理、数据建模、项目管理、设计工具/办公工具集成等核心功能，帮助企业通过产品生命周期管理提升竞争力。',
          page2: '我们在化工行业拥有丰富的咨询和实施经验，为客户在激烈的市场竞争中提升产品竞争力。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '2',
          name: '电子行业',
          title: '电子高科技行业应用场景',
          page1: '最近几年，电子高科技行业呈现出激烈的竞争局面，产品上市速度快，具有创新性的产品也不断推出。要在电子产品领域赢得竞争，必须面向消费者、关注设计、富有创新、快速推向市场，产品的全生命周期管理显得越来越重要。<br/><br/>' +
              '为了因应这一时代潮流，PLM关注数据建模和数据管理，通过项目管理和变更管理，推动部门之间、地域之间进行无缝协作，强化与第三方系统的对接，让产品生命周期管理变得快捷、高效。',
          page2: '电子高科技行业的PLM应用主要包括以下主要内容。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '3',
          name: '机械行业',
          title: '机械行业应用场景',
          page1: '机械行业拥有多品种、小批量、个性化定制的特点，对产品的结构、工艺、数据管理等提出了很高的要求。怎样建立统一的编码体系实现清晰准确的数据管理，怎样通过严谨的变更管理实现多部门多地域的研发协同，怎样与第三方系统进行无缝对接，怎样通过在线预览实现可见即可得，这些都是产品全生命周期管理过程中需要解决的问题。',
          page2: 'PLM针对机械行业的特点，致力于实现产品开发整个过程的端到端的管理，规范企业的基础管理如物料、BOM、文档，实现与第三方信息化系统与设计工具的集成，提高研发人员的设计效率。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '4',
          name: '工业设备',
          title: '工业设备行业应用场景',
          page1: '工业设备等制造业的分工合作越来越细，而部门的相对独立以及客户化定制要求，导致产品研制周期长且协同较少，研发部门到生产部门往往只有纸质文件传递，这对企业的设计生产方式、管理模式提出了严峻挑战。<br/><br/>' +
              '企业实施PLM项目管理可以使产品开发管理更加精细化，通过细化项目管理颗粒度，打破研发过程中部门之间的壁垒，从而实现研发、工艺、制造计划的协同分工与统筹安排。',
          page2: 'PLM能帮助企业在管理和工作效率方面得到极大提升，实现上游技术部门和下游生产制造部门之间信息及时传递和共享。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '5',
          name: '医药行业',
          title: '医疗器械行业应用场景',
          page1: '医疗器械行业对产品安全性和有效性要求特别高，因此对产品的设计、生产过程要求尤其严格。 医疗器械制造商的竞争也日趋激烈，使得企业在产品管理方面迫切需要通过一种更为系统化、更为全面、更高效的产品生命周期管理方法以及对应的管理平台来迎接这些新挑战。',
          page2: '医疗器械行业的PLM应用主要包括以下主要内容。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '6',
          name: '汽车行业',
          title: '汽车行业应用场景',
          page1: '汽车制造企业竞争剧烈，为了满足快速变化的客户需求，企业必须有一个协同的产品生命周期管理，才能帮助企业提高质量，降低成本，增强产品的适用性等。<br/><br/>' +
              'PLM涵盖了从市场需求分析、开发设计、测试验证、生产制造、使用维护以及报废回收等产品的整个生命周期，能够整合信息、理顺价值链，降低企业的运营和管理成本。',
          page2: 'PLM能够很好地解决公司在整车研发过程中存在的问题，既能节省时间，提高工作效率，减少重复劳动和设计错误，同时还可以记录设计过程，方便设计人员查询。<br/><br/>' +
              '主要模块： 数据管理 数据建模 变更管理 项目管理 在线可视化 第三方接入'
        },
        {
          id: '7',
          name: '其他行业',
          title: '其他行业应用场景',
          page1: 'PLM正逐渐从主流离散制造行业，更多进入零售、能源、食品饮料等非传统行业，并与人工智能、机器学习领域密切结合。在中小企业中获得的市场吸引力也持续上升。<br/><br/>' +
              'PLM最关键的功能在于优化产品研发的全管理流程，增强企业产品的市场竞争力。研发是产品力的坚强基石，PLM通过数据的关联管理可以更好地支持企业产品的研发和变更，帮助其快速回应市场消费需求。',
          page2: 'PLM由我们多年精研而成，目前已为多个行业提供专业的解决方案，未来将持续精耕，提供更好的产品和服务。'
        },
      ],
      colHeight:0,
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.plm-container {
  background: #FFFFFF;

  .module-1 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .tabs-col-0 {
          background: #E5E9FF;
          height: 171px;
          display: flex;
          align-items: center;
        }

        .tabs-col-1 {
          background: #FFE2E5;
          display: flex;
          align-items: center;
          height: 171px;
        }

        .tabs-col-2 {
          background: #FFF4E4;
          height: 171px;
          display: flex;
          align-items: center;
        }

        .tabs-col-3 {
          background: #E2F8FF;
          display: flex;
          height: 205px;
          align-items: center;
        }

        .tabs-col-4 {
          background: #E7E2FF;
          display: flex;
          align-items: center;
          height: 205px;
        }

        .tabs-col-5 {
          background: #FFE6DE;
          display: flex;
          align-items: center;
          height: 205px;
        }

        .tabs-col {
          padding: 30px 10px 20px 20px;
          margin-bottom: 10px;

          ul {
            margin-block-start: 0px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 10px;
          }

          ::marker {
            color: #1935DE;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
      }
    }
  }

  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .module-li {
          background: #FFFFFF;
          padding: 30px 20px;
          margin-bottom: 20px;
          height: 230px;

          .li-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #1935DE;

            .col-img {
              width: 25px;
              height: 24px;
              background-size: 100% 100% !important;
            }

            .li--title {
              font-size: 24px;
              font-family: 'SourceHanSansCN';
              margin-block-end: 0px;
              margin-block-start: 0px;
              color: #333333;
              padding-left: 10px;
            }
          }

          .li-introduce {
            margin-block-start: 15px;
            font-size: 16px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 0px;
          }

          ul {
            margin-block-start: 10px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "●";
            padding-left: 10px;
            color: #333333;
            padding-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
          }

          ::marker {
            color: #999999;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: x-small;
            font-family: fangsong;
          }
        }
      }
    }
  }

  .module-3 {
    background: url("~@/assets/img/solution/组1540.png") no-repeat;
    background-size: 100% 100%;
    height: 700px;
    width: 100%;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-tabs {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(43, 58, 131, 0.8);

        .tabs-list {
          color: #FFFFFF;
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          padding-bottom: 15px;
          padding-top: 15px;
          width: 33.33333%;
          text-align: center;
          cursor: pointer;
        }

        .active {
          background: #1935DE;
          color: #FFFFFF;
        }
      }

      .module--content {
        width: 1198px;
        height: 400px;
        background: rgba(33, 42, 85, 0.62);
        border: 1px solid;
        border-image: linear-gradient(0deg, rgba(208, 208, 208, 0.53), rgba(111, 129, 196, 0.53)) 1 1;
        display: flex;
        justify-content: space-between;

        .content-left {
          margin: 50px;
          border-right: 1px solid #6B80EB;
          width: 610px;
          padding-right: 50px;

          .title {
            font-family: 'SourceHanSansCN';
            color: #FFFFFF;
            font-size: 24px;
            margin-block-end: 20px;
            margin-block-start: 0px;
          }

          .content {
            font-size: 16px;
            color: #FFFFFF;
            font-family: "SourceHanSansCN";
            line-height: 24px;
          }
        }

        .content-right {
          margin: 50px;
          width: 600px;
          font-size: 16px;
          color: #FFFFFF;
          font-family: "SourceHanSansCN";
          line-height: 24px;
        }
      }
    }
  }

  .module-4 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .advantages-col {
          border: 1px solid #DDDDDD;
          background: #FFFFFF;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;

          .product {
            height: 90px;
            width: 104px;
            margin: auto;
          }

          .product-1 {
            background: url("~@/assets/img/solution/ys01.png");
            background-size: 100% 100%;
          }

          .product-2 {
            background: url("~@/assets/img/solution/ys02.png");
            background-size: 100% 100%;
          }

          .product-3 {
            background: url("~@/assets/img/solution/ys03.png");
            background-size: 100% 100%;
          }

          .product-4 {
            background: url("~@/assets/img/solution/ys04.png");
            background-size: 100% 100%;
          }

          .title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 10px;
          }
          .content{
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 10px;
            width: 250px;
          }
        }
      }
    }
  }

  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
